<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Экспорт чек-листа</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>

    <v-card-text>
      <div class="rating-popup">
        <v-container>
          <v-row>
            <v-col cols="12">
              <h4>Формат</h4>
              <v-radio-group v-model="exportType" row>
                <v-radio label="XLSX" value="XLSX"></v-radio>
                <v-radio label="PDF" value="PDF"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <h4>Период сбора информации</h4>
            </v-col>
            <v-col cols="12" sm="12">
              <input type="date" :max="maxDate" style="font-size: 20px" v-model="beginDate">
              <input type="date" :max="maxDate" style="font-size: 20px; margin-left: 50px" v-model="endDate">
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="btn btn-outline"
             text
             @click="$emit('close')">
        Закрыть
      </v-btn>
      <v-btn class="btn btn-primary"
             @click="submit()">
        Скачать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import api from "@/modules/api";

export default {
  name: "CheckListExportDialog",
  props: ['region', 'department', 'formData'],
  data: () => ({
    exportType: "XLSX",
    beginDate: null,
    endDate: null,
    maxDate: new Date().toJSON().slice(0,10)
  }),
  methods: {
    submit() {
      if (this.exportType === "XLSX") {
        this.getExportFile("/clientCentricity/formData/getExcelFile", ".xlsx");
      } else if (this.exportType === "PDF") {
        this.getExportFile("/clientCentricity/formData/getPDFFile", ".pdf");
      }
      this.$emit('close');
    },

    async getExportFile(path, fileExtension) {
      let obj = {
        clientCentricityConfigId: Number(this.formData.clientCentricityConfigId.id),
        czns: [this.department],
        beginDate: this.beginDate,
        endDate: this.endDate,
      }
      let req = await api.postDownloadFile(path, obj, {clientCentricityConfigId: this.formData.clientCentricityConfigId.id});
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = `Экспорт - чек-листа ${this.formData.id}${fileExtension}`
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    }
  },
};
</script>

<style scoped>

</style>