<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div class="edit" v-bind="attrs" v-on="on">
        <v-icon color="#0033A0">mdi-note-edit-outline</v-icon>
      </div>
    </template>
    <v-card>
      <v-card-text>
        <div class="list">
          <table cellpading="0" cellspacing="0">
            <thead>
            <tr>
              <th colspan="2">Параметр</th>
              <th v-if="checkRoles(controllerRoles)" colspan="2">Контроль</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <template v-if="questionType === 'EVENTS'">
              <td style="vertical-align: top; width: 400px;">1. {{ getParameter('events').title }}
              </td>
              <td style="width: 450px">
                <div v-if="getParameter('events').canUploadFile" class="d-flex align-center">
                  <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                       class="mr-2">
                    <path
                        d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                        fill="#777779"/>
                  </svg>
                  <template v-if="!formData.parametersUsePreviousData[getParameter('events').parameterName]">
                    <template v-if="!fileValues[getParameter('events').parameterName]">
                      <input type="file" :ref="getParameter('events').parameterName" style="display: none"
                             @change="uploadFile(formData.id, getParameter('events').parameterName);
                             $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                      <a @click="$refs[getParameter('events').parameterName].click()" class="upload-link"><i>Загрузить</i></a>
                    </template>
                    <template v-else>
                      <a @click="downloadFile(formData.id, getParameter('events').parameterName)">
                        {{ truncateStr(getFileName(fileValues[getParameter('events').parameterName]), 30) }}
                      </a>
                      <a @click="deleteFile(formData.id, getParameter('events').parameterName);
                      $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                        <v-icon>mdi-window-close</v-icon>
                      </a>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="previousFormData != null">
                      <template v-if="previousFormData.parametersFilePart[getParameter('events').parameterName] === ''">
                        <input type="file" :ref="getParameter('events').parameterName" style="display: none"
                               @change="uploadFile(formData.id, getParameter('events').parameterName);
                               $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                        <a @click="$refs[getParameter('events').parameterName][0].click()" class="upload-link"><i>Загрузить</i></a>
                      </template>
                      <template v-else>
                        <a @click="downloadFile(formData.id, getParameter('events').parameterName)">
                          {{ truncateStr(getFileName(previousFormData.parametersFilePart[getParameter('events').parameterName]), 30) }}
                        </a>
                        <a @click="deleteFile(formData.id, getParameter('events').parameterName);
                        $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                          <v-icon>mdi-window-close</v-icon>
                        </a>
                      </template>
                    </template>
                  </template>
                </div>
                <template v-for="(event, eventIndex) in events">
                  <v-container fluid  :key="'eventIndex' + eventIndex">
                    <v-row align="center">
                      <v-col xl="1" style="color: #1C1C1F; font-size: 14px;">{{ eventIndex + 1 }}.</v-col>
                      <v-col xl="10">
                        <v-text-field v-if="!formData.parametersUsePreviousData[getParameter('events').parameterName]"
                                      placeholder="Мероприятие"
                                      dense
                                      v-model="events[eventIndex]"
                                      :key="'eventIndex' + eventIndex"
                                      style="width: 300px"
                                      @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field> <!-- style="width: 300px"-->
                        <v-text-field v-else :placeholder="getParameter('events').parameterName.includes('events') ?
                                      '' : 'Мероприятие'"
                                      dense
                                      v-model="getValue(getParameter('events'), 'text')[getParameter('events').parameterName]"
                                      :key="'eventIndex' + eventIndex"
                                      style="width: 300px"
                                      @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field>
                      </v-col>
                        <v-col xl="1">
                          <a  @click="deleteEvent(eventIndex);
                          $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                            <v-icon>mdi-window-close</v-icon>
                          </a>
                        </v-col>
                    </v-row>
                  </v-container>               
                </template>
                <v-btn @click="addEvent();
                    $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"
                    outlined
                    height="25px">
                  + Добавить мероприятие</v-btn>
                <v-checkbox v-if="formData.previousFormData != null"
                            v-model="formData.parametersUsePreviousData[getParameter('events').parameterName]"
                            label="Перенести подтверждающие документы предыдущего отчетного периода"
                            @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                </v-checkbox>
                <v-checkbox v-else
                            disabled
                            indeterminate
                            label="Перенести подтверждающие документы предыдущего отчетного периода">
                </v-checkbox>
              </td>
              <template v-if="checkRoles(controllerRoles)">
                <td style="width: 10px; padding-right: 10px; padding-top: 60px">
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="green">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <br/>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="yellow">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                  <br/>
                  <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                       fill="red">
                    <circle cx="7" cy="7" r="7"/>
                  </svg>
                </td>
                <td style="padding-top: 60px">
                  <v-radio-group v-model="answerStatusesValues[getParameter('events').parameterName]">
                    <v-radio v-for="n in answerStatuses" :key="n"
                             :value="n"
                             style="width: 20px; height: 20px"
                             @change="$user.role === 'ROLE_VNII' && n !== 'WELL' ? needNotification = true : needNotification = false">
                    </v-radio>
                  </v-radio-group>
                </td>
                <td style="width: 300px">
                  <v-textarea placeholder="Примечание"
                              v-model="controllerNoteValues[getParameter('events').parameterName]"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-textarea>
                </td>
              </template>
            </template>

            <template v-else-if="questionType === 'DYNAMIC'">
              <tr>
                <td style="vertical-align: top; width: 400px;"> 1. {{getParameter('current').title + ' (текущие данные)'}}
                <td style="width: 450px">
                <v-text-field v-if="!formData.parametersUsePreviousData[getParameter('current').parameterName]"
                              placeholder="Данные за текущий период"
                              v-model="textValues[getParameter('current').parameterName]"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field>
                <v-text-field v-else placeholder="Данные за текущий период"
                              v-model="getValue(getParameter('current'), 'text')[getParameter('current').parameterName]"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field>
                <v-checkbox v-if="formData.previousFormData != null"
                            v-model="formData.parametersUsePreviousData[getParameter('current').parameterName]"
                            label="Перенести подтверждающие документы предыдущего отчетного периода"
                            @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                </v-checkbox>
<!--                <v-checkbox v-else-->
<!--                            disabled-->
<!--                            indeterminate-->
<!--                            label="Перенести подтверждающие документы предыдущего отчетного периода">-->
<!--                </v-checkbox>-->
              </td>
                <template v-if="checkRoles(controllerRoles)">
                  <td rowspan="2" style="width: 10px; padding-right: 10px; padding-left: 20px; padding-top: 130px; border: none; vertical-align: center">
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="green">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="yellow">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="red">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                  </td>
                  <td rowspan="2" style="border: none; vertical-align: center; padding-top: 130px">
                    <v-radio-group v-model="answerStatusesValues[getParameter('current').parameterName]">
                      <v-radio v-for="n in answerStatuses" :key="n"
                               :value="n"
                               style="width: 20px; height: 20px"
                               @change="$user.role === 'ROLE_VNII' && n !== 'WELL' ? needNotification = true : needNotification = false">
                      </v-radio>
                    </v-radio-group>
                  </td>
                  <td rowspan="2" style="width: 300px; border: none; vertical-align: center; padding-top: 90px">
                    <v-textarea placeholder="Примечание"
                                v-model="controllerNoteValues[getParameter('current').parameterName]"
                                @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-textarea>
                  </td>
                </template>
              </tr>
              <tr>
                <td style="vertical-align: top; width: 400px;"> 2. {{getParameter('previous').title + ' (предыдущий период)'}}
                <td style="width: 450px; padding-right: 20px">
                  <v-text-field v-if="!formData.parametersUsePreviousData[getParameter('previous').parameterName]"
                                placeholder="Данные за предыдущий период"
                                v-model="textValues[getParameter('previous').parameterName]"
                                @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field>
                  <v-text-field v-else placeholder="Данные за предыдущий период"
                                v-model="getValue(getParameter('previous'), 'text')[getParameter('previous').parameterName]"
                                @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-text-field>
                  <v-checkbox v-if="formData.previousFormData != null"
                              v-model="formData.parametersUsePreviousData[getParameter('previous').parameterName]"
                              label="Перенести подтверждающие документы предыдущего отчетного периода"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                  </v-checkbox>
                <v-checkbox v-else
                            disabled
                            indeterminate
                            label="Перенести подтверждающие документы предыдущего отчетного периода">
                </v-checkbox>
                </td>
              </tr>
            </template>

            <template v-else-if="questionType === 'LEVEL'">
              <tr v-for="(parameter, parameterIndex) in configParams" :key="parameterIndex+'index'">
                <td style="vertical-align: top; width: 400px;">{{ parameterIndex + 1 }}. {{ parameter.title }}</td>
                <td style="width: 450px">
                  <div v-if="parameter.canUploadFile" class="d-flex align-center">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="mr-2">
                      <path
                          d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                          fill="#777779"/>
                    </svg>
                    <template v-if="!formData.parametersUsePreviousData[parameter.parameterName]">
                      <template v-if="!fileValues[parameter.parameterName]">
                        <input type="file" :ref="parameter.parameterName" style="display: none"
                               @change="uploadFile(formData.id, parameter.parameterName);
                               $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                        <a @click="$refs[parameter.parameterName][0].click()" class="upload-link"><i>Загрузить</i></a>
                      </template>
                      <template v-else>
                        <a @click="downloadFile(formData.id, parameter.parameterName)">
                          {{ truncateStr(getFileName(fileValues[parameter.parameterName]), 30) }}
                        </a>
                        <a @click="deleteFile(formData.id, parameter.parameterName);
                        $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                          <v-icon>mdi-window-close</v-icon>
                        </a>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="previousFormData != null">
                        <template v-if="previousFormData.parametersFilePart[parameter.parameterName] === ''">
                          <input type="file" :ref="parameter.parameterName" style="display: none"
                                 @change="uploadFile(formData.id, parameter.parameterName);
                                 $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                          <a @click="$refs[parameter.parameterName][0].click()" class="upload-link"><i>Загрузить</i></a>
                        </template>
                        <template v-else>
                          <a @click="downloadFile(formData.id, parameter.parameterName)">
                            {{ truncateStr(getFileName(previousFormData.parametersFilePart[parameter.parameterName]), 30) }}
                          </a>
                          <a @click="deleteFile(formData.id, parameter.parameterName);
                          $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                            <v-icon>mdi-window-close</v-icon>
                          </a>
                        </template>
                      </template>
                    </template>
                  </div>
                  <v-select v-if="!formData.parametersUsePreviousData[parameter.parameterName]"
                            placeholder="Уровень комфортности"
                            :items="levels"
                            v-model="textValues[parameter.parameterName]"
                            @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false; $forceUpdate()"></v-select>
                  <v-select v-else
                            placeholder="Уровень комфортности"
                            :items="levels"
                            v-model="getValue(parameter, 'text')[parameter.parameterName]"
                            @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false; $forceUpdate()"></v-select>
                  <div style="white-space: pre-line; color: black; text-align: left;"> <i>
                    Требования для уровня:<br>
                    <span v-if="textValues[parameter.parameterName]==='1-й уровень'" >{{parameter.description_l1}}</span>
                    <span v-if="textValues[parameter.parameterName]==='2-й уровень'" >{{parameter.description_l2}}</span>
                    <span v-if="textValues[parameter.parameterName]==='3-й уровень'" >{{parameter.description_l3}}</span>

                  </i>
                  </div>
                  <v-checkbox v-if="formData.previousFormData != null"
                              v-model="formData.parametersUsePreviousData[parameter.parameterName]"
                              label="Перенести подтверждающие документы предыдущего отчетного периода"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                  </v-checkbox>
                  <v-checkbox v-else
                              disabled
                              indeterminate
                              label="Перенести подтверждающие документы предыдущего отчетного периода">
                  </v-checkbox>
                </td>
                <template v-if="checkRoles(controllerRoles)">
                  <td style="width: 10px; padding-right: 10px;">
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="green">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="yellow">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="red">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                  </td>
                  <td>
                    <v-radio-group v-model="answerStatusesValues[parameter.parameterName]">
                      <v-radio v-for="n in answerStatuses" :key="n"
                               :value="n"
                               style="width: 20px; height: 20px"
                               @change="$user.role === 'ROLE_VNII' && n !== 'WELL' ? needNotification = true : needNotification = false">
                      </v-radio>
                    </v-radio-group>
                  </td>
                  <td style="width: 300px">
                    <v-textarea placeholder="Примечание"
                                v-model="controllerNoteValues[parameter.parameterName]"
                                @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-textarea>
                  </td>
                </template>
              </tr>
            </template>

            <template v-else>
              <tr v-for="(parameter, parameterIndex) in configParams" :key="parameterIndex+'index'">
                <td style="vertical-align: top; width: 400px">{{ parameterIndex + 1 }}. {{ parameter.title }}</td>
                <td style="width: 450px">
                  <div v-if="parameter.canUploadFile" class="d-flex align-center">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                         class="mr-2">
                      <path
                          d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z"
                          fill="#777779"/>
                    </svg>
                    <template v-if="!formData.parametersUsePreviousData[parameter.parameterName]">
                      <template v-if="!fileValues[parameter.parameterName]">
                        <input type="file" :ref="parameter.parameterName" style="display: none"
                               @change="uploadFile(formData.id, parameter.parameterName);
                               $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                        <a @click="$refs[parameter.parameterName][0].click()" class="upload-link"><i>Загрузить</i></a>
                      </template>
                      <template v-else>
                        <a @click="downloadFile(formData.id, parameter.parameterName)">
                          {{ truncateStr(getFileName(fileValues[parameter.parameterName]), 30) }}
                        </a>
                        <a @click="deleteFile(formData.id, parameter.parameterName);
                        $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                          <v-icon>mdi-window-close</v-icon>
                        </a>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="previousFormData != null">
                        <template v-if="previousFormData.parametersFilePart[parameter.parameterName] === ''">
                          <input type="file" :ref="parameter.parameterName" style="display: none"
                                 @change="uploadFile(formData.id, parameter.parameterName);
                                 $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                          <a @click="$refs[parameter.parameterName][0].click()" class="upload-link"><i>Загрузить</i></a>
                        </template>
                        <template v-else>
                          <a @click="downloadFile(formData.id, parameter.parameterName)">
                            {{ truncateStr(getFileName(previousFormData.parametersFilePart[parameter.parameterName]), 30) }}
                          </a>
                          <a @click="deleteFile(formData.id, parameter.parameterName);
                          $user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                            <v-icon>mdi-window-close</v-icon>
                          </a>
                        </template>
                      </template>
                    </template>
                  </div>
                  <v-textarea
                              placeholder="Комментарий"
                              v-model="textValues[parameter.parameterName]"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-textarea>
                  <v-checkbox v-if="formData.previousFormData != null"
                              v-model="formData.parametersUsePreviousData[parameter.parameterName]"
                              label="Перенести подтверждающие документы предыдущего отчетного периода"
                              @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false">
                  </v-checkbox>
                  <v-checkbox v-else
                              disabled
                              indeterminate
                              label="Перенести подтверждающие документы предыдущего отчетного периода">
                  </v-checkbox>
                </td>
                <template v-if="checkRoles(controllerRoles)">
                  <td style="width: 10px; padding-right: 10px; padding-top: 90px">
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="green">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="yellow">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                    <br/>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"
                         fill="red">
                      <circle cx="7" cy="7" r="7"/>
                    </svg>
                  </td>
                  <td style="padding-top: 90px;">
                    <v-radio-group v-model="answerStatusesValues[parameter.parameterName]">
                      <v-radio v-for="n in answerStatuses" :key="n"
                               :value="n"
                               style="width: 0; height: 20px"
                               @change="$user.role === 'ROLE_VNII' && n !== 'WELL' ? needNotification = true : needNotification = false">
                      </v-radio>
                    </v-radio-group>
                  </td>
                  <td style="width: 300px; padding-top: 46px">
                    <v-textarea placeholder="Примечание"
                                v-model="controllerNoteValues[parameter.parameterName]"
                                @change="$user.role === 'ROLE_VNII' ? needNotification = true : needNotification = false"></v-textarea>
                  </td>
                </template>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-outline"
            text
            @click="$emit('updated'); dialog = false">
          Отмена
        </v-btn>
        <v-btn
            class="btn btn-primary"
            :disabled="submitButtonDisabled"
            @click="submit()">
          Сохранить изменения
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/modules/api";

export default {
  name: "ClientCentricityEditDialog",
  props: ['configParams', 'indexId', 'formData', 'textValues', 'fileValues', 'answerStatusesValues', 'controllerNoteValues', 'questionType', 'userDepartment'],

  data: () => ({
    dialog: false,
    apiLoaded: true,
    submitButtonDisabled: false,
    comment: null,
    answerStatuses: ['WELL', 'SATISFACTORILY', 'UNSATISFACTORY'],
    controllerRoles: ['ROLE_ADMIN', 'ROLE_VNII'],
    usePreviousData: [],
    // previousTextValues: null,
    // previousFileValues: null,
    // usePreviousValues: null,
    previousFormData: null,
    previousParametersTextPartMap: new Map(),
    previousParametersFilePartMap: new Map(),
    parametersUsePreviousDataMap: new Map(),
    events: [""],
    levels: ["3-й уровень", "2-й уровень", "1-й уровень"],
    needNotification: false,
  }),

  methods: {
    addEvent() {
      this.events.push("");
    },

    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    deleteEvent(startIndex) {
      this.events.splice(startIndex, 1);
    },
    //кнопка "Сохранить изменения"
    async submit() {
      let textValues = new Map(),
          // formDataId: Number (this.formDataId),
          // fieldName: this.configParams[0].parameterName
          validStat = true //this.validateConfig(this.config);

      let fileValues = new Map()
      let controllerNotes = new Map()
      let answerStatuses = new Map()
      let usePreviousData = new Map()

      if (validStat === true) {
        textValues = this.formTextValues();
        fileValues = this.formFileValues();
        controllerNotes = this.formControllerNotes();
        answerStatuses = this.formAnswerStatuses();
        usePreviousData = this.formUsePreviousData();
        // console.log('textValues submit', textValues);
        await this.saveData(textValues, "/clientCentricity/formData/setTextParameters");
        await this.saveData(controllerNotes, "/clientCentricity/formData/setControlletNoteParameters");
        await this.saveData(answerStatuses, "/clientCentricity/formData/setAnswerStatusParameters");
        await this.saveData(usePreviousData, "/clientCentricity/formData/setUsePreviousData");
        await this.saveData(fileValues, "/clientCentricity/formData/setPreviousFileValues");

        if (this.needNotification) {
          await this.sendNotification();
        }
      } else {
        this.errorText = validStat
      }
      this.$emit('updated');
    },

    async sendNotification() {
      let query = {departmentId: this.userDepartment.id, text: 'Сотрудик ВНИИ Труда произвёл контроль заполненного чек-листа ', title: 'Чек-лист индекса клиентоцентричности'}
      // console.log(query)
      let req = await this.$postFormDataApi("/notifs/notifyCzn", query)
      if (!req.ok) {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async saveData(obj, path) {
      this.submitButtonDisabled = true
      let req = await api.postJson(path, obj, {formDataId: this.formData.id})
      // console.log ('req', req)
      if (req.ok) {
        this.dialog = false;
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.submitButtonDisabled = false
    },

    formTextValues() {
      let map = new Map();
      if (this.questionType === 'EVENTS') {
          map.set(this.getParameter('events').parameterName, this.events.join("№№№"))
          map.set(this.getParameter('count').parameterName, this.events.length);
      } else {
        for (let parameter of this.configParams) {
            map.set(parameter.parameterName, this.textValues[parameter.parameterName])
        }
      }
      return Object.fromEntries(map)
    },

    formAnswerStatuses() {
      let map = new Map();
      for (let parameter of this.configParams) {
        if (parameter.parameterName.includes('previous')) {
          this.answerStatusesValues[parameter.parameterName] = this.answerStatusesValues[this.getParameter('current').parameterName];
        }
        map.set(parameter.parameterName, this.answerStatusesValues[parameter.parameterName])
      }
      return Object.fromEntries(map)
    },

    formControllerNotes() {
      let map = new Map();
      for (let parameter of this.configParams) {
        if (parameter.parameterName.includes('previous')) {
          this.controllerNoteValues[parameter.parameterName] = this.controllerNoteValues[this.getParameter('current').parameterName];
        }
        map.set(parameter.parameterName, this.controllerNoteValues[parameter.parameterName])
      }
      return Object.fromEntries(map)
    },

    formUsePreviousData() {
      let map = new Map();
      let value = {};
      for (let parameter of this.configParams) {
        value[parameter.parameterName] = this.formData.parametersUsePreviousData[parameter.parameterName];
        map.set(parameter.parameterName, value[parameter.parameterName])
      }
      return Object.fromEntries(map)
    },

    formFileValues() {
      let map = new Map();
      let value = {};
      for (let parameter of this.configParams) {
        if (this.formData.parametersUsePreviousData[parameter.parameterName]) {
          value[parameter.parameterName] = this.previousFormData.parametersFilePart[parameter.parameterName];
          map.set(parameter.parameterName, value[parameter.parameterName]);
        }
      }
      return Object.fromEntries(map);
    },

    async uploadFile(formDataId, parameterName) {
      let file;
      if (this.questionType === 'EVENTS') {
        file = this.$refs[parameterName].files[0];
      } else {
        file = this.$refs[parameterName][0].files[0];
      }
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        this.$refs[parameterName][0].value = "";
        return
      }
      // console.log('file', file);
      let formData = new FormData();
      formData.append('file', file);

      let req = await api.postFile(
          '/clientCentricity/formData/setFileParameter',
          {
            formDataId: formDataId,
            parameterName: parameterName,
          },
          formData,
          'POST'
      );
      console.log('req', req)
      if (req.ok) {
        console.log('upload ok');
        this.fileValues[parameterName] = req.payload.parametersFilePart[parameterName];
        this.$forceUpdate();
      } else {
        console.log('upload error', req.error);
      }
    },

    async downloadFile(formDataId, parameterName) {
      let req = await api.download("/clientCentricity/formData/getFile", {
        formDataId: formDataId,
        parameterName: parameterName,
      });
      // console.log('req', req)
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = this.getFileName(this.fileValues[parameterName]);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    async deleteFile(formDataId, parameterName) {
      let req = await api.delete("/clientCentricity/formData/deleteFileParameter", {
        formDataId: formDataId,
        parameterName: parameterName,
      });
      // console.log('req', req)
      if (req.ok) {
        console.log('delete ok');
        this.fileValues[parameterName] = "";
        this.$forceUpdate();
      } else {
        console.log('delete error');
      }
    },

    getFileName(href) {
      return href.substring(href.lastIndexOf("/") + 1);
    },

    checkRoles(userRoles) {
      for (let role of userRoles) {
        if (this.$user.subroles.includes(role)) {
          return true;
        }
      }
      return false;
    },

    async loadPreviousFormData() {
      let req = await this.$getApi("/clientCentricity/formData/getFormData",
          {formDataId : this.formData.previousFormData.id});
      if (req.ok) {
        this.previousFormData = req.payload;

        this.getParametersDataMaps();
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },

    getParametersDataMaps() {
      /*
       * TODO Много бойлералейта. Переписать
       * */
      let parametersTextPartArray = Object.entries(this.previousFormData.parametersTextPart).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));
      let parametersFilePartArray = Object.entries(this.previousFormData.parametersFilePart).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));
      let parametersUserPreviousDataArray = Object.entries(this.previousFormData.parametersUsePreviousData).map((arr) => ({
        parameterName: arr[0],
        value: arr[1],
      }));

      for (let parameterTextPart of  parametersTextPartArray) {
        this.previousParametersTextPartMap.set(parameterTextPart.parameterName, parameterTextPart.value);
      }
      for (let parameterFilePart of  parametersFilePartArray) {
        this.previousParametersFilePartMap.set(parameterFilePart.parameterName, parameterFilePart.value);
      }
      for (let parameterUserPreviousData of parametersUserPreviousDataArray) {
        this.parametersUsePreviousDataMap.set(parameterUserPreviousData.parameterName, parameterUserPreviousData.value);
      }
    },

    getValue(parameter, marker) {
      let value = {};
      if (marker === 'text') {
        value[parameter.parameterName] = this.previousParametersTextPartMap.get(parameter.parameterName);
        return value;
      }
      if (marker === 'file') {
        value[parameter.parameterName] = this.previousParametersFilePartMap.get(parameter.parameterName);
        return value;
      }
      if (marker === 'usePreviousData') {
        value[parameter.parameterName] = this.parametersUsePreviousDataMap.get(parameter.parameterName);
        return value;
      }
    },

    getParameter(relevance) {
      for (let parameter of this.configParams) {
        if (parameter.parameterName.includes(relevance)) {
          return parameter;
        }
      }
    },
  },

  async beforeMount() {
    if (this.formData.previousFormData != null) {
      await this.loadPreviousFormData();
    }
    if (this.questionType === 'EVENTS') {
      this.events = this.textValues[this.getParameter('events').parameterName].split("№№№");
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  th {
    border-bottom: 1px solid #E8E8E9;
    &:last-child {
      text-align: right;
      a {
        text-decoration: underline;
      }
      .v-btn {
        margin-left: 27px;
      }
    }
  }
  td {
    vertical-align: top;
    &:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #FC5252;
      text-align: right;
      vertical-align: top;
    }
    .v-input--selection-controls {
      margin-top: 0;
    }
    > svg {
      margin: 3px 0;
      &:first-child {
        margin-top: 8px;
      }
    }
    .v-subheader {
    height: 64px;
    font-size: 20px;
    }
  }
  .v-textarea textarea {
    height: 60px !important;
    padding: 10px !important;
  }
}

.v-dialog .v-card__actions .btn {
  width: auto !important;
}
</style>